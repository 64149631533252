import {
  GET_CHILDREN_STATS,
  GET_STUDENT_STATS,
  GET_CLASS_LIST_STATS,
  GET_CLASS_ITEM_STATS,
  GET_STUDENT_PROGRESS,
  GET_POWER_BI_CONFIG,
  GET_CLASS_LIST_ADMIN_STATS,
  GET_CLASS_DETAILS_PER_USER_STATS,
  GET_CLASS_DETAILS_BY_DATE_STATS
} from "src/redux/actions/reports";
import {
  GetChildrenStatsResponseTypes,
  GetStudentStatsResponseTypes,
  GetClassListStatsResponseTypes,
  GetClassItemStatsResponseTypes,
  GetStudentProgressResponseTypes,
  GetPowerBiConfigResponseTypes,
  StudentProgressMetricTypes,
  GetClassDetailsPerUserResponseTypes,
  GetClassDetailsByDateResponseTypes
} from "src/redux/actions/reports/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: ReportsState = {
  isLoading: false,
  childrenStats: undefined,
  studentStats: undefined,
  studentProgressMetrics: undefined,
  classListStats: undefined,
  classItemStats: undefined,
  powerBiConfig: undefined,
  classDetails: undefined,
  classDetailsByDate: undefined,
  error: undefined
};

export interface ReportsState {
  isLoading: boolean;
  childrenStats: GetChildrenStatsResponseTypes | undefined;
  studentStats: GetStudentStatsResponseTypes | undefined;
  studentProgressMetrics: StudentProgressMetricTypes[] | undefined;
  classListStats: GetClassListStatsResponseTypes | undefined;
  classItemStats: GetClassItemStatsResponseTypes | undefined;
  powerBiConfig: GetPowerBiConfigResponseTypes | undefined;
  classDetails: GetClassDetailsPerUserResponseTypes | undefined;
  classDetailsByDate: GetClassDetailsByDateResponseTypes | undefined;
  error: string | undefined;
}

const reports = (
  state = initialState,
  action: IActionType<
    | GetChildrenStatsResponseTypes
    | GetStudentStatsResponseTypes
    | GetClassListStatsResponseTypes
    | GetClassItemStatsResponseTypes
    | GetStudentProgressResponseTypes
    | GetPowerBiConfigResponseTypes
    | GetClassDetailsPerUserResponseTypes
    | GetClassDetailsByDateResponseTypes
    | string
  >
): ReportsState => {
  switch (action.type) {
    case GET_CHILDREN_STATS.REQUEST:
    case GET_STUDENT_STATS.REQUEST:
    case GET_CLASS_LIST_STATS.REQUEST:
    case GET_CLASS_ITEM_STATS.REQUEST:
    case GET_POWER_BI_CONFIG.REQUEST:
    case GET_STUDENT_PROGRESS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_CHILDREN_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        childrenStats: action.payload as GetChildrenStatsResponseTypes,
        error: undefined
      };
    }

    case GET_STUDENT_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        studentStats: action.payload as GetStudentStatsResponseTypes,
        error: undefined
      };
    }

    case GET_CLASS_LIST_STATS.SUCCESS:
    case GET_CLASS_LIST_ADMIN_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        classListStats: action.payload as GetClassListStatsResponseTypes,
        error: undefined
      };
    }

    case GET_CLASS_ITEM_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        classItemStats: action.payload as GetClassItemStatsResponseTypes,
        error: undefined
      };
    }

    case GET_STUDENT_PROGRESS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        studentProgressMetrics: action.payload.metrics as StudentProgressMetricTypes[],
        error: undefined
      };
    }

    case GET_POWER_BI_CONFIG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        powerBiConfig: action.payload as GetPowerBiConfigResponseTypes,
        error: undefined
      };
    }

    case GET_CLASS_DETAILS_PER_USER_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        classDetails: action.payload as GetClassDetailsPerUserResponseTypes,
        error: undefined
      };
    }

    case GET_CLASS_DETAILS_BY_DATE_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        classDetailsByDate: action.payload as GetClassDetailsByDateResponseTypes,
        error: undefined
      };
    }

    case GET_CHILDREN_STATS.ERROR:
    case GET_STUDENT_STATS.ERROR:
    case GET_CLASS_LIST_STATS.ERROR:
    case GET_CLASS_ITEM_STATS.ERROR:
    case GET_POWER_BI_CONFIG.ERROR:
    case GET_STUDENT_PROGRESS.ERROR:
    case GET_CLASS_DETAILS_PER_USER_STATS.ERROR:
    case GET_CLASS_DETAILS_BY_DATE_STATS.ERROR: {
      return <ReportsState>{
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reports;
