import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "src/components";
import Assignments from "src/modules/assignments";
import CreateAssignment from "src/modules/createAssignment";
import ViewAssignment from "../modules/viewAssignment/ViewAssignment";
import SchoolInfo from "../modules/schools/SchoolInfo/SchoolInfo";
import { USER_TYPES } from "../constants";
import TeacherResources from "../modules/teacherResources/teacherResources";
import Reports from "../modules/reports/Reports";
import GroupReports from "src/modules/reports/GroupReports";
import GropReportsMoreDetails from "src/modules/reports/GropReportsMoreDetails";

const NotFound = lazy(() => import("src/components/NotFound"));
const Login = lazy(() => import("src/modules/login"));
const Sso = lazy(() => import("src/modules/sso"));
const ForgotPassword = lazy(() => import("src/modules/forgotPassword"));
const Dashboard = lazy(() => import("src/modules/dashboard"));
const Library = lazy(() => import("src/modules/library"));
const LibraryByCategory = lazy(() => import("src/modules/library/LibraryByCategory"));
const ProgressReportStudents = lazy(() => import("src/modules/progressReportStudents"));
const ProgressReportClass = lazy(() => import("src/modules/progressReportClass"));
const StudentProfile = lazy(() => import("src/modules/studentProfile"));
const BookDetails = lazy(() => import("src/modules/bookDetails"));
const AssignmentDetails = lazy(() => import("src/modules/assignmentDetails"));
const BookAssignmentDetails = lazy(() => import("src/modules/bookAssignmentDetails"));
const Schools = lazy(() => import("src/modules/schools"));

const AppRouter: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/dashboard" />} />
    <Route path="/login" element={<Login />} />
    <Route path="/sso" element={<Sso />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route
      path="/dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/library"
      element={
        <PrivateRoute>
          <Library />
        </PrivateRoute>
      }
    />
    <Route
      path="/library/:catId"
      element={
        <PrivateRoute>
          <LibraryByCategory />
        </PrivateRoute>
      }
    />
    <Route
      path="/book/:id"
      element={
        <PrivateRoute>
          <BookDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-students"
      element={
        <PrivateRoute>
          <ProgressReportStudents />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-detailed"
      element={
        <PrivateRoute>
          <GroupReports />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-more-details"
      element={
        <PrivateRoute>
          <GropReportsMoreDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-class"
      element={
        <PrivateRoute>
          <ProgressReportClass />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-students/:id"
      element={
        <PrivateRoute>
          <StudentProfile />
        </PrivateRoute>
      }
    />
    <Route
      path="/progress-report-students/:id/assignments/quiz/:id"
      element={
        <PrivateRoute>
          <AssignmentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/book/:id/:id"
      element={
        <PrivateRoute>
          <BookAssignmentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/teacher-resources"
      element={
        <PrivateRoute>
          <TeacherResources />
        </PrivateRoute>
      }
    />
    <Route
      path="/assignments"
      element={
        <PrivateRoute>
          <Assignments />
        </PrivateRoute>
      }
    />
    <Route
      path="/assignments/:id"
      element={
        <PrivateRoute>
          <ViewAssignment />
        </PrivateRoute>
      }
    />
    <Route
      path="/assignments/create"
      element={
        <PrivateRoute>
          <CreateAssignment />
        </PrivateRoute>
      }
    />
    <Route
      path="/assignments/create/:id/:editMode?"
      element={
        <PrivateRoute>
          <CreateAssignment isEditing />
        </PrivateRoute>
      }
    />
    <Route
      path="schools"
      element={
        <PrivateRoute acceptedUserTypes={[USER_TYPES.SCHOOL_ADMIN, USER_TYPES.DISTRIBUTOR_ADMIN]}>
          <Schools />
        </PrivateRoute>
      }
    />
    <Route
      path="schools/:id"
      element={
        <PrivateRoute acceptedUserTypes={[USER_TYPES.SCHOOL_ADMIN, USER_TYPES.DISTRIBUTOR_ADMIN]}>
          <SchoolInfo />
        </PrivateRoute>
      }
    />
    <Route
      path="reports"
      element={
        <PrivateRoute acceptedUserTypes={[USER_TYPES.SCHOOL_ADMIN, USER_TYPES.DISTRIBUTOR_ADMIN]}>
          <Reports />
        </PrivateRoute>
      }
    />
    <Route path="/404" element={<NotFound />} />
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);

export default AppRouter;
