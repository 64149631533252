import { take, put } from "redux-saga/effects";
import { GET_CLASS_DETAILS_BY_DATE_STATS, getClassDetailsByDateActions } from "src/redux/actions/reports";

// services
import { getClassDetailsByDate } from "src/services/http/reports";

// types
import { GetClassDetailsByDateRequestTypes, GetClassDetailsByDateResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetClassDetailsByDateRequest() {
  while (true) {
    const { payload }: IActionType<GetClassDetailsByDateRequestTypes> = yield take(
      GET_CLASS_DETAILS_BY_DATE_STATS.REQUEST
    );

    try {
      const result: GetClassDetailsByDateResponseTypes = yield call(getClassDetailsByDate, payload);
      yield put(getClassDetailsByDateActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getClassDetailsByDateActions.error(e as any));
    }
  }
}

export default watchGetClassDetailsByDateRequest;
