import styled from "styled-components";

export const StyledViewAssignment = styled.div`
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }

  .studentsTableWrapper {
    margin-top: 25px;
    height: 428px;
    overflow: auto;
  }

  .detailsTableWrapper {
    margin-top: 25px;
    height: 628px;
    overflow: auto;
  }

  /* Common table styles */
  .studentsTableWrapper table,
  .detailsTableWrapper table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  /* Equalize column widths */
  .studentsTableWrapper th,
  .studentsTableWrapper td,
  .detailsTableWrapper th,
  .detailsTableWrapper td {
    padding: 8px;
    text-align: center;
    width: auto;
  }

  .studentsTableWrapper th:first-child,
  .studentsTableWrapper td:first-child,
  .detailsTableWrapper th:first-child,
  .detailsTableWrapper td:first-child {
    text-align: center; /* Center content */
  }

  .studentsTableWrapper th:last-child,
  .studentsTableWrapper td:last-child,
  .detailsTableWrapper th:last-child,
  .detailsTableWrapper td:last-child {
    text-align: center; /* Center content */
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .studentsTableWrapper tbody tr.even,
  .detailsTableWrapper tbody tr.even {
    background-color: #e2f2ff;
  }

  .studentsTableWrapper tbody tr:hover,
  .detailsTableWrapper tbody tr:hover {
    background-color: #32a2dc;
    color: #fff;
  }

  .topPanelContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 35px;
  }

  .buttonContainer button {
    background-color: #32a2dc;
  }
`;
