import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import { Layout, Loader } from "src/components";
import arrowDownIcon from "src/assets/images/down-arrow.svg";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import {
  StyledCurrentFilterDescription,
  StyledDatePicker,
  StyledDatePickerInputGroup,
  StyledDateRangeWrapper,
  StyledReportFilter
} from "./GroupReportsMoreDetails.styles";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { RootReducer } from "src/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getClassDetailsPerUserActions } from "src/redux/actions/reports";
import { ClassDetailsUserTypes } from "src/redux/actions/reports/types";
import useSchools from "src/hooks/useSchools";
import { debounce } from "lodash";

//Date locale
registerLocale("en-GB", enGB);

const GropReportsMoreDetails = () => {
  const { activeSchoolId, getGroups } = useSchools();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedClass, setSelectedClass] = useState(-999);

  const {
    group: { groups },
    reports: { classDetails, classDetailsByDate }
  }: RootReducer = useSelector((state: RootReducer) => state);

  const [isLoading, setIsLoading] = useState(true);

  const [fromDate, setFromDate] = useState<Date | null>(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  });
  const [toDate, setToDate] = useState<Date | null>(() => new Date());

  const [tableData, setTableData] = useState<ClassDetailsUserTypes[]>([]);
  const [groupsData, setGroupsData] = useState<{ id: number; label: string }[]>([]);

  const columnHelper = createColumnHelper<ClassDetailsUserTypes[][0]>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("rank", { header: "Rank", cell: (info) => info.getValue() }),
      columnHelper.accessor("name", { header: "Name", cell: (info) => info.getValue() }),
      columnHelper.accessor("value", { header: "Value", cell: (info) => info.getValue() })
    ],
    []
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const getClassReport = useCallback(
    (id: number, startDate: Date, endDate: Date, selectedClass: number) => {
      dispatch(
        getClassDetailsPerUserActions.request({
          id,
          groupId: selectedClass === -999 ? undefined : selectedClass,
          reportType: 1, // TODO: Update
          startDate: startOfDay(startDate).toISOString(),
          endDate: endOfDay(endDate).toISOString()
        })
      );
    },
    [dispatch]
  );

  const debouncedGetClassReport = useMemo(
    () => debounce((...args: Parameters<typeof getClassReport>) => getClassReport(...args), 300),
    [getClassReport]
  );

  const startOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const endOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 0);
    return newDate;
  };

  useEffect(() => {
    if (groups) {
      const allGroup = { id: -999, label: "All classes" };
      setGroupsData([allGroup, ...groups.rows.map((group) => ({ id: group.id, label: group.name }))]);
    }
  }, [groups]);

  useEffect(() => {
    if (fromDate && toDate && activeSchoolId && selectedClass) {
      getGroups();
      debouncedGetClassReport(activeSchoolId, fromDate, toDate, selectedClass);
    }
  }, [fromDate, toDate, activeSchoolId, selectedClass]);

  useEffect(() => {
    if (classDetails) {
      setTableData(classDetails);
    }
    setIsLoading(false);
  }, [classDetails, classDetailsByDate]);

  if (isLoading) return <Loader />;

  return (
    <Layout>
      <div className="assignments-page">
        {/* Breadcrumb */}
        <div className="main_breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">{t("reports_breadcrumb_progress_report")}</li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to="/progress-report-detailed">{t("sidebar_link_progress_detailed")}</Link>
              </li>
              <li className="breadcrumb-item">{"More details"}</li>
            </ol>
          </nav>
        </div>

        <StyledReportFilter>
          {/* Dropdown for Class Selection */}
          <Formik
            initialValues={{ selectedClass }}
            onSubmit={() => {
              console.log("submit");
            }}
          >
            <div className="form-group mb0">
              <div className="relative">
                <Field
                  as="select"
                  name="selectedClass"
                  className="form-control"
                  value={selectedClass}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedClass(Number(e.target.value))}
                >
                  {groupsData.map(({ id, label }) => (
                    <option value={id} key={id}>
                      {label}
                    </option>
                  ))}
                </Field>
                <span className="down-img">
                  <img src={arrowDownIcon} alt="icon" />
                </span>
              </div>
            </div>
          </Formik>

          <StyledDateRangeWrapper>
            <StyledDatePickerInputGroup>
              <label htmlFor="from-date">{t("From")}</label>
              <StyledDatePicker
                id="from-date"
                locale="en-GB"
                selected={fromDate}
                onChange={(date) => {
                  if (date instanceof Date || date === null) {
                    // Single date selection
                    setFromDate(date);
                  } else if (Array.isArray(date)) {
                    // Handle the case when date is a range (array), pick the start date as the fromDate
                    setFromDate(date[0]);
                  }
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText={t("Select a date") as string}
              />
            </StyledDatePickerInputGroup>
            <StyledDatePickerInputGroup>
              <label htmlFor="to-date">{t("To")}</label>
              <StyledDatePicker
                id="to-date"
                locale="en-GB"
                selected={toDate}
                onChange={(date) => {
                  if (date instanceof Date || date === null) {
                    // Single date selection
                    setToDate(date);
                  } else if (Array.isArray(date)) {
                    // Handle the case when date is a range (array), pick the end date as the toDate
                    setToDate(date[1]);
                  }
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText={t("Select a date") as string}
              />
            </StyledDatePickerInputGroup>
          </StyledDateRangeWrapper>
        </StyledReportFilter>

        {/* Table */}
        <StyledCurrentFilterDescription>
          <p>
            <b>{groupsData.find((classItem) => classItem.id === selectedClass)?.label}</b> (
            {fromDate?.toLocaleDateString("en-GB")} - {toDate?.toLocaleDateString("en-GB")})
          </p>
        </StyledCurrentFilterDescription>

        <div className="assignmentsTableWrapper ds-card sec_body">
          <div className="report-table-container">
            <table className="report-table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className={row.index % 2 === 0 ? "even" : "odd"}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 20, paddingRight: 10 }}>
            <Link to="/progress-report-detailed" style={{ textDecoration: "underline" }}>
              {"More details"}
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(GropReportsMoreDetails);
