import { requestTypeCreator } from "src/helpers/index";
import {
  GetChildrenStatsResponseTypes,
  GetClassListStatsRequestTypes,
  GetClassListStatsResponseTypes,
  GetClassItemStatsRequestTypes,
  GetClassItemStatsResponseTypes,
  GetStudentStatsRequestTypes,
  GetStudentStatsResponseTypes,
  GetStudentProgressRequestTypes,
  GetStudentProgressResponseTypes,
  GetPowerBiConfigResponseTypes,
  GetClassDetailsPerUserResponseTypes,
  GetClassDetailsPerUserRequestTypes,
  GetClassDetailsByDateRequestTypes,
  GetClassDetailsByDateResponseTypes
} from "./types";
import { GetGroupsRequestTypes } from "../group/types";

export const GET_CHILDREN_STATS = requestTypeCreator("GET_CHILDREN_STATS");
export const GET_CLASS_LIST_STATS = requestTypeCreator("GET_CLASS_LIST_STATS");
export const GET_CLASS_DETAILS_PER_USER_STATS = requestTypeCreator("GET_CLASS_DETAILS_PER_USER_STATS");
export const GET_CLASS_DETAILS_BY_DATE_STATS = requestTypeCreator("GET_CLASS_DETAILS_BY_DATE_STATS");
export const GET_CLASS_LIST_ADMIN_STATS = requestTypeCreator("GET_CLASS_LIST_ADMIN_STATS");
export const GET_CLASS_ITEM_STATS = requestTypeCreator("GET_CLASS_ITEM_STATS");
export const GET_STUDENT_STATS = requestTypeCreator("GET_STUDENT_STATS");
export const GET_STUDENT_PROGRESS = requestTypeCreator("GET_STUDENT_PROGRESS");
export const GET_POWER_BI_CONFIG = requestTypeCreator("GET_POWER_BI_CONFIG");

export const getChildrenStatsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CHILDREN_STATS.ERROR
  }),
  request: (payload: { userId: string }): IActionType<{ userId: string }> => {
    return {
      payload,
      type: GET_CHILDREN_STATS.REQUEST
    };
  },
  success: (payload: GetChildrenStatsResponseTypes): IActionType<GetChildrenStatsResponseTypes> => ({
    payload,
    type: GET_CHILDREN_STATS.SUCCESS
  })
};

export const getClassListStatsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CLASS_LIST_STATS.ERROR
  }),
  request: (payload: any): IActionType<GetClassListStatsRequestTypes> => {
    return {
      payload,
      type: GET_CLASS_LIST_STATS.REQUEST
    };
  },
  success: (payload: GetClassListStatsResponseTypes): IActionType<GetClassListStatsResponseTypes> => ({
    payload,
    type: GET_CLASS_LIST_STATS.SUCCESS
  })
};

export const getClassListAdminActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CLASS_LIST_ADMIN_STATS.ERROR
  }),
  request: (payload: any): IActionType<GetGroupsRequestTypes> => {
    return {
      payload,
      type: GET_CLASS_LIST_ADMIN_STATS.REQUEST
    };
  },
  success: (payload: GetClassListStatsResponseTypes): IActionType<GetClassListStatsResponseTypes> => ({
    payload,
    type: GET_CLASS_LIST_ADMIN_STATS.SUCCESS
  })
};

export const getClassItemStatsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CLASS_ITEM_STATS.ERROR
  }),
  request: (payload: any): IActionType<GetClassItemStatsRequestTypes> => {
    return {
      payload,
      type: GET_CLASS_ITEM_STATS.REQUEST
    };
  },
  success: (
    payload: GetClassItemStatsResponseTypes | undefined
  ): IActionType<GetClassItemStatsResponseTypes | undefined> => ({
    payload,
    type: GET_CLASS_ITEM_STATS.SUCCESS
  })
};

export const getStudentStatsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_STUDENT_STATS.ERROR
  }),
  request: (payload: GetStudentStatsRequestTypes): IActionType<GetStudentStatsRequestTypes> => {
    return {
      payload,
      type: GET_STUDENT_STATS.REQUEST
    };
  },
  success: (payload: GetStudentStatsResponseTypes): IActionType<GetStudentStatsResponseTypes> => ({
    payload,
    type: GET_STUDENT_STATS.SUCCESS
  })
};

export const getClassDetailsPerUserActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CLASS_DETAILS_PER_USER_STATS.ERROR
  }),
  request: (payload: GetClassDetailsPerUserRequestTypes): IActionType<GetClassDetailsPerUserRequestTypes> => {
    return {
      payload,
      type: GET_CLASS_DETAILS_PER_USER_STATS.REQUEST
    };
  },
  success: (payload: GetClassDetailsPerUserResponseTypes): IActionType<GetClassDetailsPerUserResponseTypes> => ({
    payload,
    type: GET_CLASS_DETAILS_PER_USER_STATS.SUCCESS
  })
};

export const getClassDetailsByDateActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CLASS_DETAILS_BY_DATE_STATS.ERROR
  }),
  request: (payload: GetClassDetailsByDateRequestTypes): IActionType<GetClassDetailsByDateRequestTypes> => {
    return {
      payload,
      type: GET_CLASS_DETAILS_BY_DATE_STATS.REQUEST
    };
  },
  success: (payload: GetClassDetailsByDateResponseTypes): IActionType<GetClassDetailsByDateResponseTypes> => ({
    payload,
    type: GET_CLASS_DETAILS_BY_DATE_STATS.SUCCESS
  })
};

export const getStudentProgressActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_STUDENT_PROGRESS.ERROR
  }),
  request: (payload: GetStudentProgressRequestTypes): IActionType<GetStudentProgressRequestTypes> => {
    return {
      payload,
      type: GET_STUDENT_PROGRESS.REQUEST
    };
  },
  success: (payload: GetStudentProgressResponseTypes): IActionType<GetStudentProgressResponseTypes> => ({
    payload,
    type: GET_STUDENT_PROGRESS.SUCCESS
  })
};

export const getPowerBiConfigActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_POWER_BI_CONFIG.ERROR
  }),
  request: () => {
    return {
      type: GET_POWER_BI_CONFIG.REQUEST
    };
  },
  success: (payload: GetPowerBiConfigResponseTypes): IActionType<GetPowerBiConfigResponseTypes> => ({
    payload,
    type: GET_POWER_BI_CONFIG.SUCCESS
  })
};
