import { take, put } from "redux-saga/effects";
import { GET_CLASS_DETAILS_PER_USER_STATS, getClassDetailsPerUserActions } from "src/redux/actions/reports";

// services
import { getClassDetailsPerUser } from "src/services/http/reports";

// types
import { GetClassDetailsPerUserRequestTypes, GetClassDetailsPerUserResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetClassDetailsPerUserRequest() {
  while (true) {
    const { payload }: IActionType<GetClassDetailsPerUserRequestTypes> = yield take(
      GET_CLASS_DETAILS_PER_USER_STATS.REQUEST
    );

    try {
      const result: GetClassDetailsPerUserResponseTypes = yield call(getClassDetailsPerUser, payload);
      yield put(getClassDetailsPerUserActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getClassDetailsPerUserActions.error(e as any));
    }
  }
}

export default watchGetClassDetailsPerUserRequest;
