import { requestTypeCreator } from "src/helpers/index";
import {
  CreateAssignmentRequestTypes,
  GetBookGamesRequestTypes,
  GetBookGamesResponseTypes,
  GetQuizDetailsRequestTypes,
  GetQuizDetailsResponseTypes,
  GetAssignmentRequestTypes,
  GetAssignmentResponseTypes,
  GetStudentAssignmentRequestTypes,
  GetStudentAssignmentResponseTypes,
  GetAssignmentDetailsRequestTypes,
  GetAssignmentDetailsResponseTypes,
  GetAssignmentStatusRequestTypes,
  GetAssignmentStatusResponseTypes,
  CreateFirstSectionAssignmentRequestTypes,
  AddContentToAssignmentRequestTypes,
  AddAudienceToAssignmentRequestTypes,
  UnassignContentFromAssignmentRequestTypes,
  UnassignAudienceFromAssignmentRequestTypes,
  PublishAssignmentRequestTypes,
  GetAssignmentUserActivityResponseTypes,
  GetAssignmentUserActivityRequestTypes,
  AssignmentType,
  GetAssignmentByIdRequestTypes,
  GetAssignmentStatusDetailResponseTypes
} from "./types";

export const GET_BOOK_GAMES = requestTypeCreator("GET_BOOK_GAMES");
export const CREATE_ASSIGNMENT = requestTypeCreator("CREATE_ASSIGNMENT");
export const GET_STUDENT_ASSIGNMENTS = requestTypeCreator("GET_STUDENT_ASSIGNMENTS");
export const GET_QUIZ_DETAILS = requestTypeCreator("GET_QUIZ_DETAILS");
export const GET_ASSIGNMENT_DETAILS = requestTypeCreator("GET_ASSIGNMENT_DETAILS");
export const MARK_COMPLETE_ASSIGNMENT = requestTypeCreator("MARK_COMPLETE_ASSIGNMENT");
export const GET_ASSIGNMENTS = requestTypeCreator("GET_ASSIGNMENTS");
export const GET_ASSIGNMENT_BY_ID = requestTypeCreator("GET_ASSIGNMENT_BY_ID");
export const CREATE_FIRST_STEP_ASSIGNMENT = requestTypeCreator("CREATE_FIRST_STEP_ASSIGNMENT");
export const UPDATE_FIRST_STEP_ASSIGNMENT = requestTypeCreator("UPDATE_FIRST_STEP_ASSIGNMENT");
export const ADD_CONTENT_TO_ASSIGNMENT = requestTypeCreator("ADD_CONTENT_TO_ASSIGNMENT");
export const ADD_AUDIENCE_TO_ASSIGNMENT = requestTypeCreator("ADD_AUDIENCE_TO_ASSIGNMENT");
export const UNASSIGN_CONTENT_FROM_ASSIGNMENT = requestTypeCreator("UNASSIGN_CONTENT_FROM_ASSIGNMENT");
export const UNASSIGN_AUDIENCE_FROM_ASSIGNMENT = requestTypeCreator("UNASSIGN_AUDIENCE_FROM_ASSIGNMENT");
export const PUBLISH_ASSIGNMENT = requestTypeCreator("PUBLISH_ASSIGNMENT");
export const RESET_STUDENT_ASSIGNMENTS_IS_LOADING = "RESET_STUDENT_ASSIGNMENTS_IS_LOADING";
export const GET_ASSIGNMENT_STATUS = requestTypeCreator("GET_ASSIGNMENT_STATUS");
export const GET_ASSIGNMENT_STATUS_DETAIL = requestTypeCreator("GET_ASSIGNMENT_STATUS_DETAIL");
export const GET_ASSIGNMENT_USER_ACTIVITY = requestTypeCreator("GET_ASSIGNMENT_USER_ACTIVITY");
export const RESET_STUDENT_ASSIGNMENTS = "RESET_STUDENT_ASSIGNMENTS";

export const resetStudentAssignmentsLoading = () => ({
  type: RESET_STUDENT_ASSIGNMENTS_IS_LOADING
});

export const getBookGamesActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_GAMES.ERROR
  }),
  request: (payload: GetBookGamesRequestTypes): IActionType<GetBookGamesRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_GAMES.REQUEST
    };
  },
  success: (payload: GetBookGamesResponseTypes): IActionType<GetBookGamesResponseTypes> => ({
    payload,
    type: GET_BOOK_GAMES.SUCCESS
  })
};

export const createAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: CREATE_ASSIGNMENT.ERROR
  }),
  request: (payload: CreateAssignmentRequestTypes): IActionType<CreateAssignmentRequestTypes> => {
    return {
      payload,
      type: CREATE_ASSIGNMENT.REQUEST
    };
  },
  success: () => ({
    type: CREATE_ASSIGNMENT.SUCCESS
  })
};

export const createFirstSectionAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: CREATE_FIRST_STEP_ASSIGNMENT.ERROR
  }),
  request: (
    payload: CreateFirstSectionAssignmentRequestTypes
  ): IActionType<CreateFirstSectionAssignmentRequestTypes> => {
    return {
      payload,
      type: CREATE_FIRST_STEP_ASSIGNMENT.REQUEST
    };
  },
  success: () => ({
    type: CREATE_FIRST_STEP_ASSIGNMENT.SUCCESS
  })
};

export const updateFirstSectionAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UPDATE_FIRST_STEP_ASSIGNMENT.ERROR
  }),
  request: (
    payload: CreateFirstSectionAssignmentRequestTypes
  ): IActionType<CreateFirstSectionAssignmentRequestTypes> => {
    return {
      payload,
      type: UPDATE_FIRST_STEP_ASSIGNMENT.REQUEST
    };
  },
  success: () => ({
    type: UPDATE_FIRST_STEP_ASSIGNMENT.SUCCESS
  })
};

export const addContentToAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: ADD_CONTENT_TO_ASSIGNMENT.ERROR
  }),
  request: (payload: AddContentToAssignmentRequestTypes): IActionType<AddContentToAssignmentRequestTypes> => {
    return {
      payload,
      type: ADD_CONTENT_TO_ASSIGNMENT.REQUEST
    };
  },
  success: (payload: AddContentToAssignmentRequestTypes) => ({
    payload,
    type: ADD_CONTENT_TO_ASSIGNMENT.SUCCESS
  })
};

export const unassignContentFromAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UNASSIGN_CONTENT_FROM_ASSIGNMENT.ERROR
  }),
  request: (
    payload: UnassignContentFromAssignmentRequestTypes
  ): IActionType<UnassignContentFromAssignmentRequestTypes> => {
    return {
      payload,
      type: UNASSIGN_CONTENT_FROM_ASSIGNMENT.REQUEST
    };
  },
  success: (payload: UnassignContentFromAssignmentRequestTypes) => ({
    payload,
    type: UNASSIGN_CONTENT_FROM_ASSIGNMENT.SUCCESS
  })
};

export const publishAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: PUBLISH_ASSIGNMENT.ERROR
  }),
  request: (payload: PublishAssignmentRequestTypes): IActionType<PublishAssignmentRequestTypes> => {
    return {
      payload,
      type: PUBLISH_ASSIGNMENT.REQUEST
    };
  },
  success: (payload: PublishAssignmentRequestTypes) => ({
    payload,
    type: PUBLISH_ASSIGNMENT.SUCCESS
  })
};

export const unassignAudienceFromAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.ERROR
  }),
  request: (
    payload: UnassignAudienceFromAssignmentRequestTypes
  ): IActionType<UnassignAudienceFromAssignmentRequestTypes> => {
    return {
      payload,
      type: UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.REQUEST
    };
  },
  success: (payload: UnassignAudienceFromAssignmentRequestTypes) => ({
    payload,
    type: UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.SUCCESS
  })
};

export const addAudienceToAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: ADD_AUDIENCE_TO_ASSIGNMENT.ERROR
  }),
  request: (payload: AddAudienceToAssignmentRequestTypes): IActionType<AddAudienceToAssignmentRequestTypes> => {
    return {
      payload,
      type: ADD_AUDIENCE_TO_ASSIGNMENT.REQUEST
    };
  },
  success: (payload: AddAudienceToAssignmentRequestTypes) => ({
    payload,
    type: ADD_AUDIENCE_TO_ASSIGNMENT.SUCCESS
  })
};

export const getAssignmentsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENTS.ERROR
  }),
  request: (payload: GetAssignmentRequestTypes): IActionType<GetAssignmentRequestTypes> => {
    return {
      payload,
      type: GET_ASSIGNMENTS.REQUEST
    };
  },
  success: (payload: GetAssignmentResponseTypes): IActionType<GetAssignmentResponseTypes> => ({
    payload,
    type: GET_ASSIGNMENTS.SUCCESS
  })
};

export const getAssignmentsByIdActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENT_BY_ID.ERROR
  }),
  request: (payload: GetAssignmentByIdRequestTypes) => {
    return {
      payload,
      type: GET_ASSIGNMENT_BY_ID.REQUEST
    };
  },
  success: (payload: AssignmentType): IActionType<AssignmentType> => ({
    payload,
    type: GET_ASSIGNMENT_BY_ID.SUCCESS
  })
};

export const getStudentAssignmentsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_STUDENT_ASSIGNMENTS.ERROR
  }),
  request: (payload: GetStudentAssignmentRequestTypes) => {
    return {
      payload,
      type: GET_STUDENT_ASSIGNMENTS.REQUEST
    };
  },
  success: (payload: GetStudentAssignmentResponseTypes): IActionType<GetStudentAssignmentResponseTypes> => ({
    payload,
    type: GET_STUDENT_ASSIGNMENTS.SUCCESS
  })
};

export const getQuizDetailsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_QUIZ_DETAILS.ERROR
  }),
  request: (payload: GetQuizDetailsRequestTypes) => {
    return {
      payload,
      type: GET_QUIZ_DETAILS.REQUEST
    };
  },
  success: (payload: GetQuizDetailsResponseTypes): IActionType<GetQuizDetailsResponseTypes> => ({
    payload,
    type: GET_QUIZ_DETAILS.SUCCESS
  })
};

export const getAssignmentDetailsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENT_DETAILS.ERROR
  }),
  request: (payload: GetAssignmentDetailsRequestTypes) => {
    return {
      payload,
      type: GET_ASSIGNMENT_DETAILS.REQUEST
    };
  },
  success: (payload: GetAssignmentDetailsResponseTypes): IActionType<GetAssignmentDetailsResponseTypes> => ({
    payload,
    type: GET_ASSIGNMENT_DETAILS.SUCCESS
  })
};

export const markCompleteAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: MARK_COMPLETE_ASSIGNMENT.ERROR
  }),
  request: (payload: { assignmentId: number; studentId: number | undefined; subscriptionId?: number | undefined }) => {
    return {
      payload,
      type: MARK_COMPLETE_ASSIGNMENT.REQUEST
    };
  },
  success: () => ({
    type: MARK_COMPLETE_ASSIGNMENT.SUCCESS
  })
};

export const getAssignmentStatusActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENT_STATUS.ERROR
  }),
  request: (payload: GetAssignmentStatusRequestTypes): IActionType<GetAssignmentStatusRequestTypes> => {
    return {
      payload,
      type: GET_ASSIGNMENT_STATUS.REQUEST
    };
  },
  success: (payload: GetAssignmentStatusResponseTypes): IActionType<GetAssignmentStatusResponseTypes> => ({
    payload,
    type: GET_ASSIGNMENT_STATUS.SUCCESS
  })
};

export const getAssignmentStatusDetailActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENT_STATUS_DETAIL.ERROR
  }),
  request: (payload: GetAssignmentStatusRequestTypes): IActionType<GetAssignmentStatusRequestTypes> => {
    return {
      payload,
      type: GET_ASSIGNMENT_STATUS_DETAIL.REQUEST
    };
  },
  success: (payload: GetAssignmentStatusDetailResponseTypes): IActionType<GetAssignmentStatusDetailResponseTypes> => ({
    payload,
    type: GET_ASSIGNMENT_STATUS_DETAIL.SUCCESS
  })
};

export const getAssignmentUserActivityActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_ASSIGNMENT_USER_ACTIVITY.ERROR
  }),
  request: (payload: GetAssignmentUserActivityRequestTypes): IActionType<GetAssignmentUserActivityRequestTypes> => {
    return {
      payload,
      type: GET_ASSIGNMENT_USER_ACTIVITY.REQUEST
    };
  },
  success: (payload: GetAssignmentUserActivityResponseTypes): IActionType<GetAssignmentUserActivityResponseTypes> => ({
    payload,
    type: GET_ASSIGNMENT_USER_ACTIVITY.SUCCESS
  })
};

export const resetStudentAssignmentsAction = {
  type: RESET_STUDENT_ASSIGNMENTS
};
